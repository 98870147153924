import { createData } from "datasets";

export const tariffsMoscow = [
    createData(
      "Товары с интернет-магазинов",
      "в обе стороны",
      "5$/кг",
      "5$/кг",
      "---"
    ),
    createData(
      "Текстиль",
      "в обе стороны",
      "2$/кг",
      "2$/кг",
      "до 500 кг"
    ),
    createData(
      "Текстиль",
      "в обе стороны",
      "по запросу",
      "по запросу",
      "свыше 500кг"
    ),
    createData(
      "Техника бытовая",
      "в обе стороны",
      "5$/кг",
      "5$/кг",
      "---"
    ),
    createData(
      "Электроника, ноутбуки",
      "в обе стороны",
      "13$/кг",
      "13$/кг",
      "---"
    ),
    createData(
      "Игрушки детские",
      "в обе стороны",
      "3.8$/кг",
      "3.8$/кг",
      "---"
    ),
    createData(
      "Косметика",
      "в обе стороны",
      "5.5$/кг",
      "5.5$/кг",
      "---"
    ),
    createData(
      "Продукты питания",
      "в обе стороны",
      "5$/кг",
      "5$/кг",
      "Не замороженные продукты"
    ),
    createData(
      "Жидкости",
      "в обе стороны",
      "3.5$/кг",
      "3.5$/кг",
      "---"
    ),
  ];