import facebook from '../../../assets/icons/facebook.jpg'

export default function FacebookLogin() {
	return (
		<a
			href='https://account-api.xalqtrans.com/auth/facebook'
			className='flex items-center gap-[10px] justify-center rounded-[10px] border-[#888888] border py-[18px]'
		>
			<img src={facebook} alt='facebook' />
			<p>Войти через Facebook</p>
		</a>
	)
}
