import Account from 'pages/Account'
import Addresses from 'pages/Account/Addresses'
import Contacts from 'pages/Account/Contact'
import Tariffs from 'pages/Account/Tariffs'
import Auth from 'pages/Auth'
import AuthRedirect from 'pages/Auth/AuthRedirect'
import SignIn from 'pages/Auth/SignIn'
import SignUp from 'pages/Auth/SignUp'
import Home from 'pages/Home'
import { TRoutes } from 'services/types'

const data: TRoutes = {
	protectedRoutes: [
		{
			path: '/',
			element: Home,
		},
		{
			path: '/account',
			element: Account,
			children: [
				{ path: 'points', element: Addresses },
				{ path: 'pricing', element: Tariffs },
				{ path: 'calculate', element: Contacts },
			],
		},
	],
	routes: [
		{
			path: '/auth',
			element: Auth,
			children: [
				{ path: 'sign-in', element: SignIn },
				{ path: 'sign-up', element: SignUp },
				{ path: 'redirect', element: AuthRedirect },
			],
		},
	],
}

export default data
