import { Navigate, Outlet, useLocation } from 'react-router-dom'

const Auth = () => {
	const location = useLocation()

	if (location.pathname === '/auth') return <Navigate to={'/auth/sign-in'} />

	return (
		<div className='flex justify-end min-h-screen bg-[#1A77FF]'>
			<div className='sm:max-w-[500px] w-full min-h-screen bg-white p-5 sm:p-[60px] flex flex-col justify-center'>
				<Outlet />
			</div>
		</div>
	)
}

export default Auth
