import { createData } from "datasets";

export const tariffsDubai = [
  createData(
    "Товары с интернет-магазинов",
    "Дубай - Ташкент",
    "12$/кг, 10 дней",
    "---",
    "---"
  ),
  createData(
    "Текстиль",
    "Дубай - Ташкент",
    "12$/кг, 10 дней",
    "---",
    "---"
  ),
  createData(
    "Обувь",
    "Дубай - Ташкент",
    "12$/кг, 10 дней",
    "---",
    "---"
  ),
  createData(
    "Жидкости",
    "Дубай - Ташкент",
    "12$/кг, 10 дней",
    "---",
    "---"
  ),
  createData(
    "Электроника, Ноутбуки",
    "Дубай - Ташкент",
    "13$/кг, 10 дней",
    "---",
    "---"
  ),
  createData(
    "Косметика",
    "Дубай - Ташкент",
    "12$/кг, 10 дней",
    "---",
    "---"
  ),
  createData(
    "Продукты питания",
    "Дубай - Ташкент",
    "12$/кг, 10 дней",
    "---",
    "---"
  ),
  createData(
    "Мебель",
    "Дубай - Ташкент",
    "12$/кг, 10 дней",
    "---",
    "---"
  ),
  createData(
    "Хозяйственные товары",
    "Дубай - Ташкент",
    "12$/кг, 10 дней",
    "---",
    "---"
  ),
];