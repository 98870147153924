const AuthDivider = () => {
	return (
		<div className='flex items-center justify-between gap-9'>
			<div className='w-full h-[1px] bg-[#8094A6]'></div>
			<div className='text-[#8094A6]'>или</div>
			<div className='w-full h-[1px] bg-[#8094A6]'></div>
		</div>
	)
}

export default AuthDivider
