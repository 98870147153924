export const getToday = () => {
  const months = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];
  const weekdays = [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ];

  const currentDate = new Date(); 

  const monthName = months[currentDate.getMonth()];
  const dayOfWeek = weekdays[currentDate.getDay()];

  const formattedDate = `Сегодня ${currentDate.getDate()} ${monthName}, ${dayOfWeek}`;
  return formattedDate;
};
