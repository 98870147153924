import { get } from 'lodash'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function AuthRedirect() {
	const navigate = useNavigate()

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const tokens =
			{ access_token: urlParams.get('access_token'), refresh_token: urlParams.get('refresh_token')};
		console.log(tokens);

		if (tokens) {
				fetch(`${process.env.REACT_APP_BASE_URL}/auth/me`, {
					method: 'POST',
					headers: {
						"Authorization": `Bearer ${tokens.access_token}`
					},
					body: JSON.stringify({
						token: tokens.access_token
					}),
				})
				.then((response) => {
					localStorage.setItem('access_token', tokens.access_token as string);
					localStorage.setItem('refresh_token', tokens.refresh_token as string);
					navigate('/account');
				})
				.catch((error) => {
					toast.error(
						get(error, 'response.data.message') ??
							'Произошла ошибка при входе!'
					);
					console.log(error);
					navigate('/auth/sign-in');
				})
		}
	}, [navigate])

	return <div className='flex justify-center items-center'>Redirecting...</div>
}
