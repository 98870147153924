import {
  Box,
  Button,
  ButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { tariffs } from "datasets";
import { useState } from "react";

const Tariffs = () => {
  const [rows, setRows] = useState(tariffs[0]);
  const [activeButton, setActiveButton] = useState(0);

  const handleTariff = (i: number) => {
    setRows(tariffs[i]);
    setActiveButton(i);
  };

  const directions = [
    "Россия(Москва)",
    "Китай(Гуаньчжоу)",
    "Китай(Иу)",
    "США(Нью-Йорк)",
    "ОАЭ(Дубай)",
  ];

  return (
    <div className="tariffs p-2">
      <h2 className="text-[#2D2D2D] font-semibold text-[25px] max-[640px]:text-[18px] mt-3 max-[640px]:mt-0 text-center pb-2 max-[640px]:pb-0">
        Тарифы на услуги Xalq Trans
      </h2>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "& > *": {
            m: 1,
          },
        }}
      >
        <ButtonGroup
          size="small"
          variant="outlined"
          aria-label="outlined button group"
          className="pb-2 max-[940px]:w-full flex flex-wrap"
        >
          {directions.map((item, i) => (
            <Button className="max-[940px]:w-full" onClick={() => handleTariff(i)} variant={ activeButton === i ? 'contained' : 'outlined'}>
              {item}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <TableContainer component={Paper} className="mb-4">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Вид посылки</TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">
                Направление
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">
                Стоимость, сроки(в Узбекистан)
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">
                Стоимость, сроки(из Узбекистана)
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="right">
                Доп.данные
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow
                key={i}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.category}
                </TableCell>
                <TableCell align="right">{row.direction}</TableCell>
                <TableCell align="right">{row.cost1}</TableCell>
                <TableCell align="right">{row.cost2}</TableCell>
                <TableCell align="right">{row.comment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="tips w-1/3 max-[640px]:w-full">
        <p className="font-semibold text-black/[.45]">Примечание:</p>
        <p className="text-sm text-black/[.45] pb-2">
          * Стоимость за услуги может отличаться от заявленной на день
          оформления заказа, просим предварительно уточнять стоимость у
          оператора при подтверждении данных;
        </p>
        <p className="text-sm text-black/[.45]">
          * При оформлении заказа, внимательно ознакомьтесь с перечнем
          запрещенных к перевозке товаров и убедитесь, что отправляемые Вами
          наименования отсутствуют в списке;
        </p>
      </div>
    </div>
  );
};

export default Tariffs;
