interface CountryPointProps {
    country: string, 
    address: string, 
    mapLink: string, 
    phone: string,
    hours: string,
    flag: string,
}

const CountryPoint = ({ country, address, mapLink, phone, hours, flag }: CountryPointProps) => {
    return (
        <div className="point__item w-3/12 max-[680px]:w-full max-[854px]:w-5/12 min-[1620px]:w-2/12 flex flex-col justify-end">
          <div className="flag-wrap flex top-[5%] relative">
            <div className="flag p-1 bg-white rounded-t-xl">
              <img
                className="rounded-lg w-[100px] h-[60px]"
                alt="flag"
                width={100}
                src={flag}
              ></img>
            </div>
          </div>
          <div className="point__item_content bg-white shadow-lg rounded-xl b-tl-none p-2 px-4">
            <span className="title block font-semibold text-xl text-center">
              {country}
            </span>
            <span className="address text-[#666] text-sm block pt-2 pb-2 h-[60px]">
              {address}
            </span>
            <span className="address text-[#666] text-sm block">
              <strong>Часы работы:</strong> {hours}
            </span>
            {/* <span className="address text-[#666] text-sm block pb-2">
              <strong>Телефон:</strong> {phone}
            </span> */}
            <a
              className="link-map text-[#666] text-center block text-xs underline decoration-dotted underline-offset-4 my-3"
              href={mapLink}
            >
              Посмотреть на карте
            </a>
          </div>
        </div>
    )
}

export default CountryPoint;