import CountryPoint from "components/shared-components/country-point";
import usa from "../../../assets/flags/usa.png";
import uae from "../../../assets/flags/uae.jpg";
import prc from "../../../assets/flags/prс.jpg";
import rf from "../../../assets/flags/rf.png";

const Addresses = () => {
  const pointsData = [
    {
      country: "Китай",
      address: "Guangzhou city, 广州市越秀区荔德路318号 汇富国际商贸中心 A8-103  889库房 高新 18801410427",
      mapLink: "https://maps.google.com",
      phone: "+8(344) 555-55-55",
      hours: "10:00-22:00",
      flag: prc,
    },
    {
      country: "Китай",
      address: "Iu city, 义乌市洪华小区81号楼一单元  王先生收 17501606107",
      mapLink: "https://maps.google.com",
      phone: "+8(344) 555-55-55",
      hours: "10:00-22:00",
      flag: prc,
    },
    {
      country: "США",
      address: "3047 EMMONS AVENUE BROOKLYN NY 11235",
      mapLink: "https://maps.google.com",
      phone: "+1 917 605-77-07",
      hours: "10:00-22:00",
      flag: usa,
    },
    {
      country: "Россия",
      address: "г. Москва ул.Южнопортовая 27, строение 6",
      mapLink: "https://maps.google.com",
      phone: "+7 495 893-12-48",
      hours: "10:00-22:00",
      flag: rf,
    },
    {
      country: "ОАЭ",
      address: "Dubai, Port Saeed, City Centre Metro Bus Stop 1-3",
      mapLink: "https://maps.google.com",
      phone: "+7 495 893-12-48",
      hours: "10:00-22:00",
      flag: uae,
    },
  ];

  return (
    <div className="addresses p-3 max-[640px]:pt-0">
      <h2 className="text-[#2D2D2D] font-semibold text-[25px] max-[640px]:text-[20px] max-[320px]:text-sm mt-3 max-[640px]:mt-0 text-center">
        Адреса пунктов приема посылок
      </h2>
      <p className="w-[50%] max-[640px]:w-full text-center text-sm max-[320px]:text-xs mx-auto py-2 my-4 max-[640px]:my-1 text-black/[.45] border-[#ccc] border-y">
        Все пункты приема посылок XalqTrans за пределами Республики Узбекистан предоставлюят услуги по приемке, отправке, а также таможенному оформлению Ваших посылок
      </p>
      <div className="content flex items-stretch flex-row gap-3 flex-wrap">
        {pointsData.map((item, i) => (
          <CountryPoint
            key={i}
            country={item.country}
            address={item.address}
            mapLink={item.mapLink}
            phone={item.phone}
            hours={item.hours}
            flag={item.flag}
          />
        ))}
      </div>
    </div>
  );
};

export default Addresses;
