import { logout } from 'utils'
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

export default function Home() {
	const navigate = useNavigate();

	const isAuthenticated = localStorage.getItem('access_token')

	if (isAuthenticated) return <Navigate to={'/account'} />
	navigate('auth/sign-in');
	logout();
	return (
		<div className='flex items-center justify-between container'>
			<h2>home page</h2>
			<button type='button' onClick={() => logout()}>
				log out
			</button>
		</div>
	)
}
