import { createData } from "datasets";

export const tariffsNY = [
    createData(
      "Товары с интернет-магазинов",
      "Нью-Йорк - Ташкент",
      "12$/кг, 10 дней",
      "---",
      "---"
    ),
    createData(
      "Текстиль",
      "Нью-Йорк - Ташкент",
      "12$/кг, 10 дней",
      "---",
      "---"
    ),
    createData(
      "Обувь",
      "Нью-Йорк - Ташкент",
      "12$/кг, 10 дней",
      "---",
      "---"
    ),
    createData(
      "Жидкости",
      "Нью-Йорк - Ташкент",
      "12$/кг, 10 дней",
      "---",
      "---"
    ),
    createData(
      "Электроника, Ноутбуки",
      "Нью-Йорк - Ташкент",
      "13$/кг, 10 дней",
      "---",
      "---"
    ),
    createData(
      "Косметика",
      "Нью-Йорк - Ташкент",
      "12$/кг, 10 дней",
      "---",
      "---"
    ),
    createData(
      "Продукты питания",
      "Нью-Йорк - Ташкент",
      "12$/кг, 10 дней",
      "---",
      "---"
    ),
    createData(
      "Мебель",
      "Нью-Йорк - Ташкент",
      "12$/кг, 10 дней",
      "---",
      "---"
    ),
    createData(
      "Хозяйственные товары",
      "Нью-Йорк - Ташкент",
      "12$/кг, 10 дней",
      "---",
      "---"
    ),
  ];