import google from '../../../assets/icons/google.jpg'

export default function GoogleLogin() {
	return (
		<a
			href='https://account-api.xalqtrans.com/auth/google'
			className='flex items-center gap-[10px] justify-center rounded-[10px] border-[#888888] border py-[18px] w-full'
		>
			<img src={google} alt='google' />
			<p>Войти с помощю Google</p>
		</a>
	)
}
