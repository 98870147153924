import { Navigate } from 'react-router-dom'

type ProtectedRouteProps = { children: JSX.Element }

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
	const isAuthenticated = localStorage.getItem('access_token')

	return isAuthenticated ? children : <Navigate to={'/auth/sign-in'} />
}

export default ProtectedRoute
