import { tariffsMoscow } from "./tariffs.moscow";
import { tariffsDubai } from "./tariffs.dubai";
import { tariffsIu } from "./tariffs.iu";
import { tariffsGuangzhou } from "./tariffs.guangzhou";
import { tariffsNY } from "./tariffs.ny";

export function createData(
  category: string,
  direction: string,
  cost1: string,
  cost2: string,
  comment: string
) {
  return { category, direction, cost1, cost2, comment };
}

export const tariffs = [
  tariffsMoscow,
  tariffsGuangzhou,
  tariffsIu,
  tariffsNY,
  tariffsDubai,
];
