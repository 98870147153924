import { createData } from "datasets";

export const tariffsGuangzhou = [
  createData(
    "Товары с интернет-магазинов",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
  createData(
    "Текстиль",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
  createData(
    "Обувь",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
  createData(
    "Жидкости",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
  createData(
    "Электроника",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
  createData(
    "Косметика",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
  createData(
    "Продукты питания",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
  createData(
    "Мебель",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
  createData(
    "Хозяйственные товары",
    "в обе стороны",
    "по запросу",
    "по запросу",
    "---"
  ),
];