import { Button } from '@mui/material'
import { ErrorMessage, Field } from 'formik'
import { get } from 'lodash'
import { Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import Container from 'modules'

import fields from 'components/fields'
import AuthDivider from 'components/shared-components/auth-divider'
import AuthQuestion from 'components/shared-components/auth-question'

import {
	FacebookLogin,
	GoogleLogin,
} from 'components/shared-components/social-buttons'
import logo from '../../../assets/logo.jpg'

const SignIn = () => {
  const navigate = useNavigate();

	// const isAuthenticated = localStorage.getItem('access_token')

	// if (isAuthenticated) return <Navigate to={'/account'} />

	return (
		<Container.Form
			url='/auth/signin'
			method='post'
			fields={[
				{
					name: 'emailOrNumber',
					required: true,
				},
				{
					name: 'password',
					required: true,
				},
			]}
			onSuccess={(data) => {
				localStorage.setItem('access_token', get(data, 'access_token'))
				localStorage.setItem('refresh_token', get(data, 'refresh_token'))
				// navigate('/account')
				window.location.reload();
			}}
			onError={(error) => {
				toast.error(
					get(error, 'response.data.message') ??
						'This user is already registered or an error occurred!'
				)
			}}
		>
			{({ isSubmitting, isLoading }) => {
				return (
					<div>
						<div className='mb-[50px] flex justify-center sm:justify-start'>
							<img src={logo} alt='logo' />
						</div>

						<div className='space-y-10'>
							<h1 className='text-[#2D2D2D] font-bold text-[23px] sm:text-[30px] text-center sm:text-left'>
								Авторизация
							</h1>

							<div className='flex flex-col gap-5'>
								<div>
									<Field
										label='Почта или номер телефона'
										name='emailOrNumber'
										type='text'
										component={fields.Input}
									/>
									<ErrorMessage
										name='emailOrNumber'
										className='mt-2 text-red-500'
										component={'div'}
									/>
								</div>
								<div>
									<Field
										label='Пароль'
										name='password'
										type='password'
										component={fields.Input}
									/>
									<ErrorMessage
										name='password'
										className='mt-2 text-red-500'
										component={'div'}
									/>
								</div>
							</div>

              <div>
                <Button
                  disabled={isLoading}
                  type="submit"
                  className="w-full !py-4 text-center bg-blue-500"
                  variant="contained"
                >
                  Войти
                </Button>
              </div>
            </div>

            <div className="mt-[30px] mb-12">
              <AuthDivider />
            </div>

						<div className='space-y-4'>
							<GoogleLogin />
							<FacebookLogin />
						</div>

            <div className="mt-[18px]">
              <AuthQuestion type="signin" />
            </div>
          </div>
        );
      }}
    </Container.Form>
  );
};

export default SignIn;
