import { Button } from "@mui/material";
import fields from "components/fields";
import AuthDivider from "components/shared-components/auth-divider";
import AuthQuestion from "components/shared-components/auth-question";
import { ErrorMessage, Field } from "formik";
import Container from "modules";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { get } from "lodash";
import { toast } from "react-toastify";
import facebook from "../../../assets/icons/facebook.jpg";
import google from "../../../assets/icons/google.jpg";
import logo from "../../../assets/logo.jpg";
import {
	FacebookLogin,
	GoogleLogin,
} from 'components/shared-components/social-buttons'

const SignUp = () => {
  const navigate = useNavigate();

  // const isAuthenticated =
  // 	cookie.get('access_token') && cookie.get('refresh_token')

  const isAuthenticated = localStorage.getItem("access_token");

  if (isAuthenticated) return <Navigate to={"/account"} />;

  return (
    <Container.Form
      url="/auth/signup"
      method="post"
      fields={[
        { name: "name", required: true },
        { name: "lastname", required: true },
        { name: "email", required: true },
        { name: "contact_number", required: true },
        { name: "password", required: true },
      ]}
      onSuccess={(data) => {
        // cookie.set('access_token', get(data, 'access_token'))
        // cookie.set('refresh_token', get(data, 'refresh_token'))
        localStorage.setItem("access_token", get(data, "access_token"));
        localStorage.setItem("refresh_token", get(data, "refresh_token"));
        navigate("/");
      }}
      onError={(error) => {
        console.log(error);
        toast.error(
          get(error, "response.data.message") ??
            "This user is already registered or an error occurred!"
        );
      }}
    >
      {({ isSubmitting, isLoading }) => {
        return (
          <div>
            <div className="mb-[50px] flex justify-center sm:justify-start">
              <img src={logo} alt="logo" />
            </div>

            <div className="space-y-10">
              <h1 className="text-[#2D2D2D] font-bold text-[23px] sm:text-[30px] text-center sm:text-left">
                Регистрация
              </h1>

              <div className="flex flex-col gap-5">
                <div>
                  <Field
                    label="Имя"
                    name="name"
                    type="text"
                    component={fields.Input}
                  />
                  <ErrorMessage
                    name="name"
                    className="mt-2 text-red-500"
                    component={"div"}
                  />
                </div>
                <div>
                  <Field
                    label="Фамилия"
                    name="lastname"
                    type="text"
                    component={fields.Input}
                  />
                  <ErrorMessage
                    name="lastname"
                    className="mt-2 text-red-500"
                    component={"div"}
                  />
                </div>
                <div>
                  <Field
                    label="Электронная почта"
                    name="email"
                    type="email"
                    component={fields.Input}
                  />
                  <ErrorMessage
                    name="email"
                    className="mt-2 text-red-500"
                    component={"div"}
                  />
                </div>
                <div>
                  <Field
                    label="Телефонный номер"
                    name="contact_number"
                    type="text"
                    component={fields.Input}
                  />
                  <ErrorMessage
                    name="contact_number"
                    className="mt-2 text-red-500"
                    component={"div"}
                  />
                </div>
                <div>
                  <Field
                    label="Пароль"
                    name="password"
                    type="password"
                    component={fields.Input}
                  />
                  <ErrorMessage
                    name="password"
                    className="mt-2 text-red-500"
                    component={"div"}
                  />
                </div>
                {/* <div>
									<Field
										label='Подтвердите пароль'
										name='confirmation'
										type='password'
										component={fields.Input}
									/>
									<ErrorMessage
										name='confirmation'
										className='mt-2 text-red-500'
										component={'div'}
									/>
								</div> */}
              </div>

              <div>
                <Button
                  disabled={isLoading}
                  type="submit"
                  className="w-full !py-4 text-center bg-blue-500"
                  variant="contained"
                >
                  Зарегистрироваться
                </Button>
              </div>
            </div>

            <div className="mt-[30px] mb-12">
              <AuthDivider />
            </div>

            <div className="space-y-4">
              <GoogleLogin />
              <FacebookLogin />
            </div>

            <div className="mt-[18px]">
              <AuthQuestion type="signup" />
            </div>
          </div>
        );
      }}
    </Container.Form>
  );
};

export default SignUp;
