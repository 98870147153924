import axios, { InternalAxiosRequestConfig } from 'axios'

const http = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 30000,
})

http.interceptors.request.use(
	(configs: InternalAxiosRequestConfig) => {
		const token = localStorage.getItem('access_token')

		if (token) {
			configs.headers.Authorization = `Bearer ${token}`
		}

		return configs
	},
	(error) => {
		return Promise.reject(error)
	}
)

// http.interceptors.response.use(
// 	(response) => response,
// 	(error) => {
// 		if (get(error, 'response.status') === 401) {
// 			logout()
// 		}
// 		return Promise.reject(error)
// 	}
// )

export default http
