import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";
import RoutesWrapper from "./routes";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";


import { useGetAll } from 'hooks'

import 'react-toastify/dist/ReactToastify.css'

function App() {
	// useGetAll({
	// 	url: '/auth/me',
	// 	name: 'me',
	// 	queryOptions: {
	// 		enabled: localStorage.getItem('access_token') ? true : false,
	// 		// enabled: cookie.get('access_token') ? true : false,
	// 	},
	// });
	console.log(process.env.NODE_ENV)
	//console.log(process.env.REACT_APP_BASE_URL, '--')
	const navigate = useNavigate();
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	useEffect(() => {
	  (async () => {
		const res = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/me`, {
		  headers: {
			Authorization: `Bearer ${localStorage.getItem("access_token")}`,
		  },
		});
		if (!res.ok || +res?.status !== 200) {
		  return setIsAuthenticated(false);
		  // return navigate("/auth/sign-in");
		}
		return setIsAuthenticated(true);
		//return navigate('/account');
	  })();
	}, []);

	return (
		<div className='App'>
			<ToastContainer />
			<RoutesWrapper isAuthenticated={isAuthenticated} />
		</div>
	)
}

export default App;
