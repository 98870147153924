import Layout from "layout";
import ProtectedRoute from "middleware/ProtectedRoute";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import data from "./data";
import { useEffect, useState } from "react";

const RoutesWrapper = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  console.log(isAuthenticated);
  // const isAuthenticated = localStorage.getItem('access_token')

  return (
    <Routes>
      {isAuthenticated ? (
        <>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            {data.protectedRoutes.map((item) => {
              return (
                <Route
                  path={item.path}
                  element={<item.element />}
                  key={item.path}
                >
                  {item.children
                    ? item.children.map((innerItem) => (
                        <Route
                          path={innerItem.path}
                          element={<innerItem.element />}
                          key={innerItem.path}
                        />
                      ))
                    : null}
                </Route>
              );
            })}
          </Route>
        </>
      ) : (
        <>
          {data.routes.map((item) => (
            <Route path={item.path} element={<item.element />} key={item.path}>
              {item.children
                ? item.children.map((innerItem) => (
                    <Route
                      path={innerItem.path}
                      element={<innerItem.element />}
                      key={innerItem.path}
                    />
                  ))
                : null}
            </Route>
          ))}
        </>
      )}

      <Route
        path="*"
        element={
          isAuthenticated ? (
            <Navigate to="/account" />
          ) : (
            <Navigate to="/auth/sign-in" />
          )
        }
      />
    </Routes>
  );
};

export default RoutesWrapper;
