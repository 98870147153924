import { Link } from 'react-router-dom'

interface AuthQuestionProps {
	type: 'signin' | 'signup'
}

const AuthQuestion = ({ type }: AuthQuestionProps) => {
	const typeCheck = () => {
		switch (type) {
			case 'signup':
				return (
					<>
						<p className='text-[#8094A6]'>У вас есть аккаунт?</p>
						<Link to='/auth/sign-in'>Войти</Link>
					</>
				)
			case 'signin':
				return (
					<>
						<p className='text-[#8094A6]'>У вас нет аккаунта?</p>
						<Link to='/auth/sign-up'>Зарегистрироваться сейчас</Link>
					</>
				)
			default:
				break
		}
	}

	return (
		<div className='flex items-center gap-1 justify-center text-sm'>
			{typeCheck()}
		</div>
	)
}

export default AuthQuestion
